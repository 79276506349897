import { useEffect, useState } from "react";
import { storage } from "../firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";

import "../styles/sponzori.css";
import download from "../assets/download.svg";
import Button from "../components/Button";

const imageListRef = ref(storage, "sponzori/");

function Sponzori() {
  const getSponsorManual = async () => {
    const fileRef = ref(storage, "dokumenti/sponzorski_paket.pdf");
    getDownloadURL(fileRef)
      .then((url) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = "sponzorski_paket.pdf";
        link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [pictures, setPictures] = useState([]);

  async function getImages(ref) {
    const imageUrls = [];
    const res = await listAll(ref);
    await Promise.all(
      res.items.map(async (image) => {
        const url = await getDownloadURL(image);
        imageUrls.push(url);
      })
    );
    return imageUrls;
  }

  async function getFolders() {
    const response = await listAll(imageListRef);
    const folders = sortByFolderName(response);

    for (let i of folders.prefixes) {
      const result = await getImages(i);
      setPictures((prev) => [
        ...prev,
        {
          name: i.name,
          images: result,
        },
      ]);
    }
  }

  function sortByFolderName(folders) {
    const sortBy = ["Platinum", "Silver", "Bronze"];
    folders.prefixes.sort((a, b) => {
      return (
        sortBy.indexOf(a.fullPath.slice(9)) -
        sortBy.indexOf(b.fullPath.slice(9))
      );
    });

    return folders;
  }

  useEffect(() => {
    getFolders();
  }, []);

  return (
    <>
      <div className="section column">
        <div className="wrapper">
          {pictures.map((obj, i) => (
            <>
              {(obj.name === "Platinum") & (obj.images.length > 0) ? (
                <div>
                  <div key={i}>
                    <h1 className="title">Platinum</h1>
                    <div className="divider" />
                    <div className="sponsor-grid">
                      {obj.images.map((img, index) => (
                        <img
                          className="sponsor-icon"
                          src={img}
                          alt="sponsor logo"
                          loading="lazy"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}

              {(obj.name === "Silver") & (obj.images.length > 0) ? (
                <div>
                  <div key={i}>
                    <h1 className="title">Silver</h1>
                    <div className="divider" />
                    <div className="sponsor-grid">
                      {obj.images.map((img, index) => (
                        <img
                          className="sponsor-icon"
                          src={img}
                          alt="sponsor logo"
                          loading="lazy"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}

              {(obj.name === "Bronze") & (obj.images.length > 0) ? (
                <div>
                  <div key={i}>
                    <h1 className="title">Bronze</h1>
                    <div className="divider" />
                    <div className="sponsor-grid">
                      {obj.images.map((img, index) => (
                        <img
                          className="sponsor-icon"
                          src={img}
                          alt="sponsor logo"
                          loading="lazy"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ))}
        </div>
      </div>

      <div className="section column big-gap">
        <h1 className="title centered">Želiš postati sponzor?</h1>
        <h2 className="subtitle centered">Radujemo se svakoj suradnji!</h2>
        <div className="text centered">
          Preuzmite naš sponzorski paket i provjerite što nudimo. Ako ste
          zainteresirani za sponzorstvo, javite nam se na naš{" "}
          <a id="mail" href="/kontakt">
            mail
          </a>
          .
        </div>
        <Button
          onClick={getSponsorManual}
          text={"Preuzmi PDF"}
          icon={download}
        />
      </div>
    </>
  );
}

export default Sponzori;
